import React, { useState, useEffect } from "react"
import logo from "../images/logo/punjabsweets.svg"
import Language from "./language"
import { injectIntl, Link } from "gatsby-plugin-react-intl"
import { useDebouncedCallback } from 'use-debounce';

const Header = ({ intl }) => {
  const [lastYPos, setLastYPos] = useState(0);
  
  const setNavClass = () => {
    const yPosDelta = 4;
    const yPos = window.pageYOffset || document.documentElement.scrollTop;

    if (Math.abs(lastYPos - yPos) >= yPosDelta) {
      const header = document.querySelector('.header-container');
      const height = header.clientHeight;
      const menuNav = document.querySelector('.menu-nav');

      if (yPos > lastYPos && yPos > height){
          header.classList.add('hide-nav');
          menuNav && menuNav.classList.add('hide-nav');
      } else {
        header.classList.remove('hide-nav');
        menuNav && menuNav.classList.remove('hide-nav');
      }
      setLastYPos(yPos);
    }
  }

  const [debouncedSetNavClass] = useDebouncedCallback(
    setNavClass,
    100,
    { maxWait: 250 }
  )

  useEffect(() => {
    window.addEventListener('scroll', debouncedSetNavClass);

    return function cleanup() {
      window.removeEventListener('scroll', debouncedSetNavClass);
    };
  }, [debouncedSetNavClass]);

  return (
    <header className="header-container">
        <a className="header-logo" href="/"><img className="header-logo__img" src={logo} alt="Punjab Sweets"/></a>
        <nav className="header-nav" role="navigation">
          <ul className="header-nav__ul">
            <li className="header-nav__li"><Link to="/" activeClassName="header-nav__li--active">{intl.formatMessage({ id: "nav.home" })}</Link></li>
            <li className="header-nav__li"><Link to="/menu/" activeClassName="header-nav__li--active">{intl.formatMessage({ id: "nav.menu" })}</Link></li>
            <li className="header-nav__li"><Link to="/contact/" activeClassName="header-nav__li--active">{intl.formatMessage({ id: "nav.contact" })}</Link></li>
            <li className="header-nav__li"><Link to="/about/" activeClassName="header-nav__li--active">{intl.formatMessage({ id: "nav.about" })}</Link></li>
            <li className="header-nav__li header-nav__li--outlined"><Language/></li>
          </ul>
        </nav>
    </header>
  )
}

export default injectIntl(Header)
