import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"

const languageName = {
  en: "en",
  fr: "fr",
}

const Language = () => {
  const getDisplayLanguge = (currentLocale) => {
    if (currentLocale === languageName.en) {
      return languageName.fr
    } else if (currentLocale === languageName.fr) {
      return languageName.en
    }
    return languageName.fr
  }

  return (
    <div>
      <IntlContextConsumer>
        {({ language: currentLocale }) =>
            <button
              className="language__button"
              key={getDisplayLanguge(currentLocale)}
              onClick={() => changeLocale(getDisplayLanguge(currentLocale))}
              style={{
                cursor: `pointer`,
              }}
            >
              {languageName[getDisplayLanguge(currentLocale)]}
            </button>
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language