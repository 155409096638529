import React from "react"
import logo from "../images/logo/punjabsweetsvertical.svg"
import { injectIntl, Link } from "gatsby-plugin-react-intl"

const Footer = ({ intl }) => (
  <footer className="footer-container">
    <div className="footer footer-top">
      <div className="footer__column footer__column--intro">
        <a className="footer-logo" href="/"><img className="footer-logo__img" src={logo} alt="Punjab Sweets"/></a>
        <p className="footer__text footer__text--slogan">{intl.formatMessage({ id: "footer.slogan" })}</p>
      </div>
      <div className="footer__column footer__column--intro footer__column--nav">
        <div className="footer-nav footer-right">
          <h4 className="footer__title footer__title--nav">{intl.formatMessage({ id: "footer.navigate" })}</h4>
          <ul className="footer-nav__ul">
            <li className="footer-nav__li"><Link to="/" className="footer-nav__link" activeClassName="footer-nav__link--active">{intl.formatMessage({ id: "nav.home" })}</Link></li>
            <li className="footer-nav__li"><Link to="/menu/" className="footer-nav__link" activeClassName="footer-nav__link--active">{intl.formatMessage({ id: "nav.menu" })}</Link></li>
            <li className="footer-nav__li"><Link to="/contact/" className="footer-nav__link" activeClassName="footer-nav__link--active">{intl.formatMessage({ id: "nav.contact" })}</Link></li>
            <li className="footer-nav__li"><Link to="/about/" className="footer-nav__link" activeClassName="footer-nav__link--active">{intl.formatMessage({ id: "nav.about" })}</Link></li>
          </ul>
        </div>
      </div>
      <div className="footer__column">
        <h4 className="footer__title">{intl.formatMessage({ id: "footer.location" })}</h4>
        <p className="footer__text">
          975 Rue Jean-Talon O,<br/>
          Montréal, QC H3N 1S9
        </p>
        <h4 className="footer__title footer__title--second">{intl.formatMessage({ id: "nav.contact" })}</h4>
        <p className="footer__text">
          514-273-3000
        </p>
      </div>
      <div className="footer__column">
        <div className="footer-right">
          <h4 className="footer__title">{intl.formatMessage({ id: "opening-hours.title" })}</h4>
          <p className="footer__text">
            {intl.formatMessage({ id: "opening-hours.days" })}<br/>
            {intl.formatMessage({ id: "opening-hours.hours" })}<br/>
            {intl.formatMessage({ id: "footer.hours-text" })}
          </p>
        </div>
      </div>
    </div>
    <div className="footer footer-bottom">
      <div className="footer__text footer__text--last"><a className="footer__link"  href="https://www.tusmanakhter.com" target="_blank" rel="noreferrer noopener">{intl.formatMessage({ id: "footer.developer" })}</a></div>
      <div className="footer__text footer__text--last">2021 © Punjab Sweets Inc.</div>
    </div>
  </footer>
)

export default injectIntl(Footer)
